import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Couldn't find that</h1>
    <p>Looks like you've ventured where nobody has before...</p>
    <h2>Perhaps you'd like to take a look at these instead</h2>
    <ul>
      <li>
        <a href="https://blog.sethcorker.com/">Benevolent Bytes Blog</a>
      </li>
      <li>
        <a href="https://dev.to/darthknoppix">My articles on dev.to</a>
      </li>
    </ul>
  </Layout>
)

export default NotFoundPage
